import React from "react";
import Hero from "../src/Components/Hero";
import Footer from "../src/Components/Footer";
import Header from "../src/Components/Header";
import CardComponent from "../src/Components/CardComponent";
import styled from "../node_modules/styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const DynamicTypography = styled.div`
  text-align: center;
  margin-bottom: 2%;
  margin-top: 5%;
  font-weight: 200;
  color: black;
  font-size: 3em;

  @media screen and (max-width: 750px) {
    margin-top: 10%;
  }
`;

const cardComponents = [
  {
    id: 1,
    text: "A Unique 4-Step Process",
    image: "gears"
  },
  {
    id: 2,
    text: "Guaranteed Satisfaction",
    image: "apartment"
  },
  {
    id: 3,
    text: "We Come to You",
    image: "van"
  }
];

const App = () => {
  return (
    <div className="App" style={{ height: "100%" }}>
      <Header />
      <Hero text="Honest and Dependable" text2="Jame's Carpet Cleaning" />
      <DynamicTypography>Why You Will Love Us</DynamicTypography>
      <Wrapper>
        {cardComponents.map(cardcomponents => (
          <CardComponent
            key={cardcomponents.id}
            text={cardcomponents.text}
            image={cardcomponents.image}
          />
        ))}
      </Wrapper>
      <Footer />
    </div>
  );
};

export default App;
