import React from "react";
import Card from "../../node_modules/@material-ui/core/Card";
import Typography from "../../node_modules/@material-ui/core/Typography";
import CardContent from "../../node_modules/@material-ui/core/CardContent";

const styles = {
  card: {
    width: "300px",
    height: "auto",
    alignSelf: "center",
    margin: "2rem",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative"
  },
  links: {
    color: "black",
    textDecoration: "none",
    fontFamily: "Roboto",
    fontSize: "28px"
  }
};

const CardComponent = ({ text, image }) => (
  <div>
    <Card style={styles.card} className="grow">
      <img
        src={require(`../assets/${image.toLowerCase()}.jpeg`)}
        alt="James Carpet Cleaning"
        style={{
          objectFit: "cover",
          width: "100%",
          height: "auto"
        }}
      />
      <CardContent style={styles.title}>
        <Typography
          style={{ textAlign: "center", fontSize: "23px" }}
          variant="headline"
          component="h4"
        >
          {text}
        </Typography>
      </CardContent>
    </Card>
  </div>
);

export default CardComponent;
