import React from "react";
import Typography from "../../node_modules/@material-ui/core/Typography";
import { FaFacebook, FaYelp } from "react-icons/fa";

const styles = {
  footerDemensions: {
    height: "120px",
    maxWidth: "100%",
    backgroundColor: "rgb(50, 54, 57)",
    color: "white",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  icontStyles: {
    height: "2em",
    width: "2em",
    borderRadius: "50px"
  }
};

const Footer = () => (
  <div>
    <section style={styles.footerDemensions}>
      <div style={{ paddingLeft: "10px" }}>
        <Typography
          variant="h4"
          style={{
            textDecoration: "underline",
            color: "white",
            fontSize: "1.3em",
            marginBottom: "5px"
          }}
        >
          Contact Me:
        </Typography>
        <Typography style={{ textDecoration: "none", color: "white" }}>
          1-916-257-8794
        </Typography>
        <Typography style={{ textDecoration: "none", color: "white" }}>
          jharding26@gmail.com
        </Typography>
        <Typography
          style={{ textDecoration: "none", color: "white", fontSize: ".3em" }}
        >
          Business License: 00833758
        </Typography>
      </div>
      <div
        style={{
          width: "100px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center"
        }}
      >
        <div>
          <a href="https://www.yelp.com/biz/james-carpet-cleaning-citrus-heights-2">
            <FaYelp color="white" size="2em" />
          </a>
        </div>
        <div>
          <a href="">
            <FaFacebook color="white" size="2em" />
          </a>
        </div>
      </div>
    </section>
  </div>
);

export default Footer;
