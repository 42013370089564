import React from "react";
import styled from "../../node_modules/styled-components";
import flag from "../assets/flag.jpeg";
import { Typography, Button } from "@material-ui/core";

const styles = {
  fontStyle: {
    color: "white",
    textAlign: "center",
    fontWeight: "200",
    wordWrap: "break-word"
  },
  flag: {
    height: "60vh",
    width: "100%",
    filter: "brightness(30%)"
  }
};

const ResponsiveHero = styled.div`
  position: absolute;

  @media only screen and (max-device-width: 375px) {
    margin-top: 10%;
    height: 100vh;
  }
`;

const ResponsoveFlag = styled.img`
  height: 60vh;
  width: 100%;
  filter: brightness(30%);

  @media only screen and (max-device-width: 375px) {
    height: 100vh;
    margin-top: 10%;
  }
`;

const Hero = ({ text, text2 }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    }}
  >
    <ResponsoveFlag src={flag} alt="American Flag" />
    <ResponsiveHero>
      <Typography style={styles.fontStyle} variant="display3">
        {text2}
      </Typography>
      <Typography style={styles.fontStyle} variant="display1">
        Carpet & Upholstery Cleaning
      </Typography>
      <Typography style={styles.fontStyle} variant="title">
        {text}
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Button
          style={{
            marginTop: "10px",
            backgroundColor: "#3DC7F4"
          }}
          href="mailto:jharding26@gmail.com"
        >
          <Typography style={styles.fontStyle}>Request a Quote</Typography>
        </Button>
      </div>
    </ResponsiveHero>
  </div>
);

export default Hero;
