import React, { Component } from "react";
import AppBar from "../../node_modules/@material-ui/core/AppBar";
import Toolbar from "../../node_modules/@material-ui/core/Toolbar";
import Typography from "../../node_modules/@material-ui/core/Typography";
import Button from "../../node_modules/@material-ui/core/Button";

class Header extends Component {
  constructor() {
    super();
    this.state = { open: false };
  }

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div>
        <AppBar position="absolute">
          <Toolbar
            variant="dense"
            style={{ backgroundColor: "rgb(50, 54, 57)" }}
          >
            <Button
              style={{
                backgroundColor: "#3DC7F4"
              }}
              href="tel:19162578794"
            >
              <Typography
                style={{
                  color: "white",
                  textDecoration: "none"
                }}
              >
                1-916-257-8794
              </Typography>
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default Header;
